import { Flex, Image, Text, Grid, GridItem, Box } from '@chakra-ui/react';
import '../styles/styled.css';
import CircleIcon from '../assets/svgs/CircleSVG';

const assets1 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-1.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-2.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-3.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-4.webp' }
];

const assets2 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/desarrollador/develop-1.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/desarrollador/develop-2.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/desarrollador/develop-3.webp' }
];

export const listImages = [
    {
        id: 1,
        images: [
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex position={"relative"} className='col2-mon' width={"100%"} padding={"3rem"}>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[0].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        fontFamily={'Shne-Breit-Regular'}
                        float='right'
                        fontSize={'19px'}
                        bottom='70px'
                        right='80px'
                        p={4}
                        pl={16}>
                        INGRESO CONTROLADO
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[1].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontFamily={'Shne-Breit-Regular'}
                        fontSize={'19px'}
                        bottom='70px'
                        right='100px'
                        p={4}
                        pl={16}>
                        BUSINESS CENTER
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[2].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontFamily={'Shne-Breit-Regular'}
                        fontSize={'19px'}
                        bottom='70px'
                        right='100px'
                        p={4}
                        pl={16}>
                        ÁREAS VERDES
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon'>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[3].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                    <Text
                        display={'flex'}
                        justifyContent={'center'}
                        position={'absolute'}
                        color='white'
                        float='left'
                        fontFamily={'Shne-Breit-Regular'}
                        fontSize={'19px'}
                        bottom='70px'
                        right='100px'
                        p={4}
                        pl={16}>
                        MURO PERIMETRAL
                    </Text>
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
        ]
    },
    {
        id: 2,
        images: [
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto' position='relative'>
                <Image src={assets2[0].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
                <Text
                    position='absolute'
                    left='35%'
                    bottom='40px'
                    color='#FFFFFF'
                    fontFamily={'Shne-Breit-Regular'}
                    align='center'
                >
                    Conchas chinas, <br />
                    Puerto Vallarta
                </Text>
            </Flex>,
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets2[1].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
                <Text
                    position='absolute'
                    left='38%'
                    bottom='40px'
                    color='#FFFFFF'
                    fontFamily={'Shne-Breit-Regular'}
                    align='center'
                >
                    Torre Skalia,<br />
                    Zapopan
                </Text>
            </Flex>,
            <Flex placeItems={'start'} w='100%' h={'100%'} mx='auto'>
                <Image src={assets2[2].imgUrl} objectFit='cover' w='auto' h={'100%'} alt='icon' />
                <Text
                    position='absolute'
                    left='35%'
                    bottom='40px'
                    color='#FFFFFF'
                    fontFamily={'Shne-Breit-Regular'}
                    align='center'
                >
                    Rancho contento,<br />
                    Zapopan
                </Text>
            </Flex>
        ]
    }
];
