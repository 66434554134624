import React, { useState } from 'react';
import {
    Flex,
    Grid,
    GridItem,
    Image,
    Text,
    Input,
    Checkbox,
    Button,
    Link,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import { toast } from 'react-toastify';

const ContactMobile = () => {
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/contact.webp' }
    ];

    const postForm = () => {
        let data_send = {
            fields: [
                {
                    name: "firstname",
                    value: name,
                },
                {
                    name: "lastname",
                    value: lastName,
                },
                {
                    name: "email",
                    value: email,
                },
                {
                    name: "phone",
                    value: phone,
                },
            ],
            context: {
                ipAddress: "3.14.97.137",
                pageUri: "www.grupoguia.mx",
                pageName: "Grupo Guia | Tecnosur",
            },
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data_send),
        };
        fetch(
            "https://api.hsforms.com/submissions/v3/integration/submit/5930840/376c0c7a-7245-4082-9128-c630dbf59b07",
            // "03479824-13fb-4023-a9c1-a35a76088498",
            requestOptions
        )
            .then((response) => response.json())
            .then((data_w) => {
                window.gtag('event', 'formulario_contacto');
                window.fbq('track', 'Lead', { content_name: 'contacto' });
                toast.success("¡Tus datos fueron eviados correctamente!", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                setName("");
                setLastName("");
                setEmail("");
                setPhone("");
            });
    }
    return (
        <Flex
            flexDirection={"column"}
            color={"#545454"}
            mt={10}
            mb={5}>
            <Flex height={"100%"} justifyContent={"center"} alignItems={"center"}>
                <Image src={assets[0].imgUrl} width="400" height="400" alt='icon' />
            </Flex>
            <Flex textAlign={"center"} flexDirection={"column"} alignItems={"center"} mt={10}>
                <Text
                    fontSize={"30px"}
                    fontFamily={'AktivGrotesk-Medium'}
                    width={"100%"}
                    color={'#0F312C'}
                >
                    TECNOSUR
                </Text>
                <Text
                    fontSize="16px"
                    fontFamily={'AktivGrotesk-Regular'}
                    width={"100%"}
                    textAlign={'justify'}
                    padding={'2em'}
                    pb={0}
                    color={"#545454"}
                >
                    Compártenos tus datos y descubre más sobre Tecnosur.
                </Text>
                <Flex justifyContent={"center"} id="contacto" mt={10}>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        postForm();
                    }}>
                        <Grid padding={'2em'} templateColumns={"repeat(2, 1fr)"} w='100%' maxW={'5xl'} mx='auto' gap={10}>
                            <GridItem colSpan={2}>
                                <FormControl isRequired>
                                    <FormLabel>Nombre</FormLabel>
                                    <Input
                                        variant='flushed'
                                        htmlSize={'100'}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <FormControl isRequired>
                                    <FormLabel>Apellido</FormLabel>
                                    <Input
                                        variant='flushed'
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={2}>
                                <FormControl isRequired>
                                    <FormLabel>Número de teléfono</FormLabel>
                                    <Input
                                        variant='flushed'
                                        type='number'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>

                            </GridItem>
                            <GridItem colSpan={2}>
                                <FormControl isRequired>
                                    <FormLabel>Correo</FormLabel>
                                    <Input
                                        variant='flushed'
                                        type='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>

                            </GridItem>
                            <GridItem colSpan={2} textAlign={'left'} mt={4}>
                                <Checkbox>
                                    <Text>
                                        He leído y acepto el
                                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' color='blue' textDecoration={'underline'} ml={2} isExternal>
                                            Aviso de privacidad
                                        </Link>
                                    </Text>
                                </Checkbox>
                            </GridItem>

                            <GridItem colSpan={2} textAlign={'center'} mt={4}>
                                <Button
                                    type='submit'
                                    color='#000000'
                                    bg='rgba(172, 255, 197, 1)'
                                    _hover={{
                                        bg: '#7CB48D'
                                    }}
                                    px={8}
                                    fontWeight='400'
                                    rounded={'none'}
                                >
                                    Enviar
                                </Button>
                            </GridItem>
                        </Grid>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default ContactMobile