import React, {useState} from 'react';
import { Flex, Text, useMediaQuery, useTheme, IconButton } from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import '../styles/styled.css';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {listImages} from '../resource/list.js';

const Carousel = () => {

    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const slidePrev = () => {
        activeIndex===0?(
          setActiveIndex(listImages[0].images.length-1)):
        setActiveIndex(activeIndex - 1);
    };
    
    const slideNext = () => {
        activeIndex===listImages[0].images.length-1?(
          setActiveIndex(0))
            : setActiveIndex(activeIndex + 1)
    };

  return (
    <Flex id='carousel' marginTop={isGreaterThanMd?"8rem":"5rem"}className='wrapper-all-sections-carrusel-alice' 
        flexDirection={"column"}  alignItems={"center"} m={"4rem 0px"} mb={isGreaterThanMd ? "1rem" : "0px"}>
            <Flex flexDirection={"column"} alignItems={"center"}>
                <Text 
                    id='amenidades'
                    textTransform={"uppercase"} 
                    fontSize={isGreaterThanMd?'50px':'25px'}
                    fontFamily='Shne-Breit-Bold'
                    mb={0}
                    textAlign={'center'}
                    color='rgba(84, 84, 84, 1)'
                    lineHeight={isGreaterThanMd?'50px':'30px'}
                >
                    Amenidades
                </Text>
                <Text 
                    color='rgba(84, 84, 84, 1)' 
                    fontSize={isGreaterThanMd?'26px':'20px'} 
                    fontFamily='Shne-Breit-Regular'
                    textAlign={"center"}
                    mt={2}
                    lineHeight={isGreaterThanMd?'36px':'21.6px'}
                    >
                    Espacios cool para tus negocios de alto impacto
                </Text>
            </Flex>
            <Flex className='section-carrusel-mons' justifyContent={"center"} flexDirection={"row"} width={"100%"}  alignItems={"center"}>
                <IconButton
                    icon={<ChevronLeftIcon  width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-izq'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={slidePrev}
                    position="relative"
                    color='white'
                    left={{ base: "16", md: 40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
                <AliceCarousel
                    mouseTracking
                    animationDuration={900}
                    disableButtonsControls
                    activeIndex={activeIndex}
                    items={listImages[0].images}
                    responsive={true}
                    controlsStrategy="responsive"
                    animationType='fadeout'
                    autoPlay={true}
                    autoPlayInterval={3000}
                    infinite={true}
                    keyboardNavigation={true}
                    disableDotsControls
                    autoHeight={false}
                    maxHeight="200px"
                />
                <IconButton
                    icon={<ChevronRightIcon  color='white' width="64.000000pt" height="54.000000pt"/>}
                    border="0"
                    className='btn-carrusel-mons-drc'
                    background={"transparent"}
                    transitionDuration=".3s"
                    onClick={slideNext}
                    position="relative"
                    color='white'
                    left={{ base: "-16", md: -40 }}
                    _hover={{ background: "transparent" }}
                    zIndex="2"
                />
            </Flex>
            
        </Flex>
    )
}

export default Carousel