import React from 'react';
import {
    Flex,
    Text,
    useMediaQuery,
    useTheme,
    Grid,
    GridItem,
    Image
} from '@chakra-ui/react';

const Description = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/description/description.webp' },
    ];

    return (
        <Flex
            my={16}
            paddingTop={'5rem'}
            flexDirection={"column"}
            paddingBottom={isGreaterThanMd ? "1rem" : 0}
            w='100%'
            id='proyecto'
        >
            <Grid
                templateColumns={isGreaterThanMd ? "repeat(5, 1fr)" : "repeat(2, 1fr)"}
                px={isGreaterThanMd ? 24 : 8}
                maxW={'5xl'} margin='auto'
                pb={14}
                pt={{ base: 5, md: 0 }}
            >
                <GridItem
                    display={isGreaterThanMd ? "none" : "grid"}
                    colSpan={2}
                    justifyContent='center'
                    mb={10}
                >
                    <Flex
                        paddingLeft={isGreaterThanMd ? "2rem" : 0}
                        justifyContent='center'
                        h='100%'
                    >
                        <Image
                            src={assets[0].imgUrl}
                            width='100%'
                            height='auto'
                            alt='amenidades'
                            style={{
                                objectFit: 'contain',
                                background: 'center'
                            }}
                        />
                    </Flex>
                </GridItem>
                <GridItem
                    colSpan={isGreaterThanMd ? 3 : 2}
                    mb={isGreaterThanMd ? 0 : 8}
                    alignSelf='center'
                >
                    <Flex
                        flexDirection={"column"}
                        py={isGreaterThanMd ? 20 : 0}
                        px={isGreaterThanMd ? 10 : 2}
                        textAlign={isGreaterThanMd ? 'start' : 'justified'}
                    >
                        <Flex>
                            <Text
                                fontFamily="Shne-Breit-Regular"
                                fontSize={isGreaterThanMd ? '18px' : '14px'}
                                lineHeight={isGreaterThanMd ? '19.2px' : '14.4px'}
                                color='rgba(84, 84, 84, 1)'
                            >
                                Tecnosur es un parque industrial, diseñado para impulsar
                                a las pymes y empresas consolidadas. Aquí podrán crear
                                conexiones y oportunidades de negocio exitosas.
                                <br /><br /><br />
                                {/* • Desde 600 m² hasta 1,640 m² <br /><br /> */}
                                • Desde 600 m² <br /><br />
                                • 69,000 m² de área vendible <br /><br />
                                • 80 terrenos
                            </Text>
                        </Flex>
                    </Flex>
                </GridItem>
                <GridItem
                    display={isGreaterThanMd ? "grid" : "none"}
                    colSpan={2}
                    justifyContent='center'
                >
                    <Flex
                        paddingLeft={isGreaterThanMd ? "2rem" : 0}
                        justifyContent='center'
                        h='100%'
                    >
                        <Image
                            src={assets[0].imgUrl}
                            width='100%'
                            height='auto'
                            alt='amenidades'
                            style={{
                                objectFit: 'contain',
                                background: 'center'
                            }}
                        />
                    </Flex>
                </GridItem>
            </Grid>
        </Flex>
    );
}

export default Description;
