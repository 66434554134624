import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Image,
  Divider
} from '@chakra-ui/react';
import {
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  HamburgerIcon
} from '@chakra-ui/icons';
import "../styles/styled.css";
import '../styles/nav.css';
import ButtonModal from './ButtonModal';

export default function Nav() {
  const { isOpen, onToggle } = useDisclosure();

  const icons = [
    { imageUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/logo.png' }
  ];

  return (
    <Box>
      <Flex id='mobile'
        w='100%'
        position='fixed'
        bg='#FFF'
        // bg={useColorModeValue('transparent', 'gray.800')}
        color={useColorModeValue('#808080', '#808080')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
        zIndex={8}>
        <Flex h='80px' mt={{ md: 2 }} pb={2} flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Link my='auto' href='#' w={{ base: '80px', lg: '130px', xl: '180px' }}>
            <Image ml={{ base: 0, md: 10 }} src={icons[0].imageUrl} w='180px' h='auto' alt="logo" />
          </Link>
          <Flex display={{ base: 'none', md: 'none', lg: 'flex' }} ml={20}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Stack
          mt={{ md: 1 }}
          ml={{ base: 4, md: 0 }}
          flex={{ base: 1, md: 0 }}
          justify={'center'}
          direction={'row'}
          marginRight={{ base: 0, md: 10 }}
          spacing={2}>
          <ButtonModal
            title={"DESCARGAR BROCHURE"}
            bg='rgba(172, 255, 197, 1)'
            color={"#000000"}
            txtWeight='400'
            bgHover='#7CB48D'
            idForm={"84d22ac2-b299-4c5e-a7c7-fe6dd5827e65"}
          />
        </Stack>

        <Flex
          flex={{ base: 1, md: 'auto' }}
          justifyContent={"center"}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} color='#0F312C' /> : <HamburgerIcon w={5} h={5} color='#0F312C' />

            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />

        </Flex>
      </Flex>

      <Divider
        id='divisor'
        display={{ base: 'flex', lg: 'none' }}
        width={'100%'}
        boxShadow={'2px 2px 2px #000000'}
      />

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('#808080', 'gray.100');
  const linkHoverColor = useColorModeValue('#545454', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={{ lg: 0, xl: 4 }} alignItems='center'>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} alignItems='center'>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger id="popover" textAlign={"center"}>
              <Link
                href={navItem.href ?? '#'}
                fontSize={"16px"}
                fontWeight={500}
                color={linkColor}
                pr={5}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}

              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>

    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      p={4}
      mt={20}
      zIndex={4}
      bg='#FFF'
      position='fixed'
      w='100%'
      display={{ lg: 'none' }}>

      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />

      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={'#808080'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}

      </Flex>


      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: 'Proyecto',
    href: '#proyecto',
  },
  {
    label: 'Amenidades',
    href: '#amenidades',
  },
  // {
  //   label: 'Prototipos',
  //   href: '#prototipos',
  // },
  {
    label: 'Planes de pago',
    href: '#planes-pago',
  },
  {
    label: 'Desarrollador',
    href: '#desarrollador',
  },
  {
    label: 'Contacto',
    href: '#contacto',
  }
];