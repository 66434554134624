import React from 'react'
import Presentacion from '../../components/Presentacion';
import Description from '../../components/Description';
import Carousel from '../../components/Carousel';
import SecondCarousel from '../../components/SecondCarousel';
import CTA from '../../components/CTA';
import Planes from '../../components/Planes';
import Cercanias from '../../components/Cercanias';
import Desarrollador from '../../components/Desarrollador';
import Contacto from '../../components/Contacto';
import Footer from '../../components/Footer';
import ContactMobile from '../../components/ContactMobile';
import Button from '../../components/ButtonPage';
import ButtonActionModal from '../../components/ButtonActionModal';
import { Flex, Divider, useTheme, useMediaQuery, Box } from '@chakra-ui/react';

const Home = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    return (
        <Box fontFamily='Shne-Breit-Halbfett'>
            <Flex position={'fixed'} right={{ base: 2, md: '20px' }} bottom={5} zIndex={3}>
                <ButtonActionModal
                    title={"Envíanos un Whatsapp"}
                    isImage={true}
                    srcImg={'https://boomerang.s3.us-east-2.amazonaws.com/landings/torremm/icons/redes-sociales/wa.svg'}
                    idFormHs={"60929a67-795c-4e9c-afd0-2fd7bcec16a7"}
                    thankYouPage={"thankyoupage-contactar-whatsapp"}
                    bgColor={"#D2457A"}
                    hover={"#C42862"}
                    txtSize={700}
                />
            </Flex>
            <Presentacion />
            <Description />
            <CTA
                ctaNum={0}
                text1={'A minutos de López Mateos Sur'}
                text2={'y el Centro de Tlajomulco'}
                font1={'50px'}
                font2={'50px'}
                fontMb1={'25px'}
                fontMb2={'25px'} />
            <Carousel />
            <Cercanias />
            <SecondCarousel />
            {/* <Planes /> */}
            <CTA
                ctaNum={1}
                text1={'Conexión que acelera'}
                text2={'tu crecimiento'}
                font1={'50px'}
                font2={'50px'}
                fontMb1={'25px'}
                fontMb2={'20px'} />
            <Desarrollador />
            {isGreaterThanMd ? <Contacto /> : <ContactMobile />}
            <Flex paddingLeft={"1.5rem"} paddingRight={"1.5rem"}>
                <Divider orientation='horizontal' />
            </Flex>
            <Footer />
        </Box>
    )
}

export default Home