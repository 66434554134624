import React from 'react';
import { Button } from '@chakra-ui/react';
import { handleDownload } from '../resource';
import brochure from '../resource/pdf/TecnoparkSur_Brochure.pdf';
//import ModalCard from './ModalCard';

const ButtonModal = ({ title, bg, color, txtWeight, bgHover, colorHover, border, thankYouPage, idForm }) => {
    // const { isOpen, onOpen, onClose } = useDisclosure();

    return ( 
        <>
            <Button
                fontSize={'sm'}
                variant={'link'}
                bg={bg}
                color={color}
                fontFamily={'Shne-Breit-Regular'}
                width={"fit-content"}
                border={border ? border : "transparent"}
                fontWeight={txtWeight}
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                as='a'
                borderRadius='0px'
                _hover={{
                    bg: bgHover,
                    color: colorHover,
                    cursor: 'pointer'
                }}
                onClick={() => handleDownload(brochure, "TecnoparkSur_Brochure.pdf")}>
                {title}
            </Button>
            {/* {isOpen ?
                <ModalCard 
                    isOpen={isOpen} 
                    onClose={onClose}
                    title={title}
                    thankyoupage={thankYouPage}
                    idForm={idForm} /> : null
            } */}
        </>
    );
}
 
export default ButtonModal;