import { Flex, Image, Text, Grid, GridItem, Box } from '@chakra-ui/react';
import '../styles/styled.css';
import CircleIcon from '../assets/svgs/CircleSVG';

const assets1 = [
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-5.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-6.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-7.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-8.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-9.webp' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/carousel/carousel-10.webp' }
];

export const listImages = [
    {
        id: 1,
        images: [
            <Flex className='container-cols-mon' key={0}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[5].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[0].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon' key={1}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[1].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon' key={2}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[1].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[2].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon' key={3}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[2].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[3].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[4].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon' key={4}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[3].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[4].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[5].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>,
            <Flex className='container-cols-mon' key={5}>
                <Flex className='col1-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[4].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
                <Flex className='col2-mon' width={"100%"} padding={"3rem"} position='relative'>
                    <img
                        style={{ width: "100%" }}
                        src={assets1[5].imgUrl}
                        width='100'
                        height='100'
                        alt='icon' />
                </Flex>
                <Flex className='col3-mon' borderRadius={"24px"} backgroundImage={`linear-gradient(#0f312c71, #0f312c71), url('${assets1[0].imgUrl}')`} width={"20%"} margin={"3rem 0px"} backgroundSize={"cover"}></Flex>
            </Flex>
        ]
    }
];

