import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Flex, Spinner } from "@chakra-ui/react";
import Home from "./pages/tecnosur/Home";

const SwitchRouter = () => {
    return (
        <Suspense
            fallback={
                <Flex
                    minH="100vh"
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Spinner />
                    Cargando...
                </Flex>
            }>
            <Routes>
                <Route path='/' element={<Home />} />
            </Routes>
        </Suspense>
    )
}

const Router = () => {
    return (
        <BrowserRouter basename="/tecnosur">
            <SwitchRouter />
        </BrowserRouter>
    );
}

export default Router