import React, { useEffect, useState } from 'react';
import lightTheme from "./themes/ligth";
import Router from './Router';
import { ChakraProvider } from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isCleanCache, setIsCleanCache] = useState(false);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    if (!isCleanCache) {
      clearCacheData();
      setIsCleanCache(true);
    }
  }, []);

  return (
    <ChakraProvider theme={lightTheme}>
      <Router />
      <ToastContainer />
    </ChakraProvider>
  );
}

export default App;
