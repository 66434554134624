import React from 'react'
import { Flex, Stack, Text, Link, useMediaQuery, useTheme, Image, Box } from '@chakra-ui/react';
import "../styles/footer.css";

const Footer = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/desarrollador/icon-ibosa.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/assets/icons/icon-guia-logo-r-1.svg' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/footer/icon-fb.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/footer/icon-insta.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/footer/icon-what.png' }
    ];

    return (
        <Flex my={"3rem"} mx={isGreaterThanMd ? "3rem" : "1.2rem"} flexDirection={"column"} id='Footer'>
            <Flex display={isGreaterThanMd ? 'flex' : 'none'}>
                <Flex
                    className='footer-logos'
                    width={isGreaterThanMd ? "33%" : "100%"}
                    height={"fit-content"}
                    fontFamily='Shne-Breit-Regular'
                >
                    <Stack direction='row'>
                        <Text
                            fontSize={'xs'}
                            fontWeight='300'
                            textAlign={'center'}
                            pr={4}
                        >
                            Una creación de:
                            <Image
                                style={{ margin: "auto" }}
                                src={assets[0].imgUrl}
                                width='auto'
                                height='50px'
                                alt='Logo TyA'
                            />
                        </Text>
                        <Text
                            fontSize={'xs'}
                            fontWeight='300'
                            textAlign={'center'}
                            pr={4}
                        >
                            Comercializado por:
                            <Image
                                src={assets[1].imgUrl}
                                width='auto'
                                height='30px'
                                alt='Logo Guia'
                            />
                        </Text>
                    </Stack>
                </Flex>
                <Flex className='footer-copyright-top' width={"33%"} justifyContent='center'>
                    <Text fontSize='sm' color='#545454' fontFamily='Shne-Breit-Regular'>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>
                            Aviso de privacidad
                        </Link>
                    </Text>
                </Flex>
                <Flex
                    className='footer-iconos'
                    width={isGreaterThanMd ? "33%" : "100%"}
                    height={"fit-content"}
                    justifyContent={"end"}
                >
                    <Stack justifyContent={"end"} direction='row'>
                        <Link
                            href='https://www.facebook.com/profile.php?id=61550275246755'
                            isExternal
                        >
                            <img
                                style={{ marginRight: "1rem" }}
                                src={assets[2].imgUrl}
                                width='19'
                                height='20'
                                alt='Facebook'
                            />
                        </Link>
                        <Link
                            href='https://instagram.com/tecnosur_industrial?igshid=MzRlODBiNWFlZA=='
                            isExternal
                        >
                            <img
                                style={{ marginRight: "1rem" }}
                                src={assets[3].imgUrl}
                                width='30'
                                height='20'
                                alt='Facebook'
                            />
                        </Link>
                        <Link
                            href="https://wa.me/523330337907"
                            isExternal
                        >
                            <img
                                style={{ marginRight: "1rem" }}
                                src={assets[4].imgUrl}
                                width='30'
                                height='42'
                                alt='Facebook'
                            />
                        </Link>
                    </Stack>
                </Flex>
            </Flex>
            <Flex display={isGreaterThanMd ? 'none' : 'flex'} alignSelf='center'>
                <Stack direction='col' alignItems={'center'}>
                    <Link
                        href='https://www.facebook.com/profile.php?id=61550275246755'
                        isExternal
                    >
                        <Image
                            style={{ marginRight: "1rem" }}
                            src={assets[2].imgUrl}
                            width='30px'
                            height='42px'
                            alt='Facebook'
                        />
                    </Link>
                    <Link
                        href='https://instagram.com/tecnosur_industrial?igshid=MzRlODBiNWFlZA=='
                        isExternal
                    >
                        <Box w='20px'>
                            <Image
                                style={{ marginRight: "1rem" }}
                                src={assets[3].imgUrl}
                                width='42px'
                                height='42px'
                                alt='Instagram'
                            />
                        </Box>
                    </Link>
                    <a
                        // onClick="gtag('event', 'telefono_whatsapp');fbq('track', 'Contact', {content_name:'whatsapp'});"
                        href="https://wa.me/523330337907"
                    >
                        <Image
                            style={{ marginRight: "1rem" }}
                            src={assets[4].imgUrl}
                            width='42px'
                            height='42px'
                            alt='Wpp'
                        />
                    </a>
                </Stack>
            </Flex>
            <Flex display={isGreaterThanMd ? 'none' : 'flex'} pt={6} alignSelf='center'>
                <Stack justifyContent={"center"} direction='row' alignItems={'center'}>
                    <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                        Una creación de:
                        <Image
                            style={{ margin: "auto" }}
                            src={assets[0].imgUrl}
                            width='170'
                            height='35'
                            alt='Logo IBOSA'
                        />
                    </Text>
                    <Text fontSize={'xs'} fontWeight='300' textAlign={'center'} pr={4}>
                        Comercializado por:
                        <Image
                            style={{ height: "auto" }}
                            src={assets[1].imgUrl}
                            width='160'
                            height='75'
                            alt='Logo Guia'
                        />
                    </Text>
                </Stack>
            </Flex>
            <Flex className='footer-copyright-bottom' pt={6} justifyContent={"center"} marginTop={isGreaterThanMd ? "2rem" : 2}>
                <Flex textAlign={"center"} justifyContent={"center"}>
                    <Text fontSize='sm' color='#545454' fontWeight={400}>
                        Copyright &#169; 2023 &#169; All Rights Reserved - Powered by Grupo Guia
                        <br />
                        <Link href='https://grupoguia.mx/Aviso-de-privacidad' isExternal>Aviso de privacidad</Link>
                    </Text>
                </Flex>
            </Flex>
        </Flex >
    )
}

export default Footer
