import React from 'react'
import { Flex, Text, useMediaQuery, useTheme, Link, Image } from '@chakra-ui/react';

const Cercanias = () => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);

    const assets = [
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/cercanias/mapa-cercanias.webp' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/cercanias/mobile_mapa_cercanias_.svg' },
        { imgUrl: isGreaterThanMd ? 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/cercanias/puntos-cercanias.webp' : 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/cercanias/mobile_cercaniastext.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/maps.svg' }
    ];

    return (
        <Flex
            paddingTop="2rem"
            marginBottom={"4rem"}
            justifyContent='center'
            py={isGreaterThanMd ? 4 : 10}
        >
            <Flex
                alignItems={"center"}
                flexDirection={"column"}
                color={"#545454"}
            >
                <Flex
                    alignItems={"center"}
                    textAlign={"center"}
                    flexDirection={"column"}
                    px={4}
                >
                    <Text
                        color='rgba(84, 84, 84, 1)'
                        textTransform={"uppercase"}
                        fontSize={isGreaterThanMd ? '50px' : '25px'}
                        lineHeight={isGreaterThanMd ? '60px' : '36px'}
                        mb={0}
                        fontFamily='Shne-Breit-Bold'
                        textAlign={'center'}
                        fontWeight='700'
                    >
                        Cercanías
                    </Text>
                    <Text
                        fontFamily='Shne-Breit-Regular'
                        marginTop={"1.5rem"}
                        textAlign={'center'}
                        marginBottom={"1rem"}
                        fontSize={isGreaterThanMd ? '24px' : '22px'}
                        lineHeight={isGreaterThanMd ? '30.47px' : '23.85px'}
                    >
                        Conecta con las principales vías logísticas y alguno de los puertos más importantes del país                    </Text>
                </Flex>
                <Flex mt={10} justifyContent={"center"}>
                    <img
                        src={assets[0].imgUrl}
                        style={{ width: "100%", height: 'auto' }}
                        width="500"
                        height="500"
                        alt='cercanias'
                        onClick={() => {
                            window.open('https://maps.app.goo.gl/wxxRXCiupnE47tdaA',
                                '_blank')
                        }}
                    />
                </Flex>
                <Flex justifyContent={"center"} mt={-5}>
                    <img
                        src={assets[1].imgUrl}
                        style={{
                            width: "100%",
                            height: 'auto'
                        }}
                        width="500"
                        height="500"
                        alt='cercanias'
                        onClick={() => {
                            window.open('https://maps.app.goo.gl/wxxRXCiupnE47tdaA',
                                '_blank')
                        }}
                    />
                </Flex>
                <Flex
                    flexDirection={isGreaterThanMd ? "row" : "column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={10}
                >
                    <Image
                        m={isGreaterThanMd ? "0px 1rem" : "1rem"}
                        src={assets[2].imgUrl}
                        width={isGreaterThanMd ? "52px" : "40px"}
                        height={isGreaterThanMd ? "47px" : "36px"}
                        alt="descripción" />
                    <Link
                        color={"#0882C6"}
                        fontSize={"16px"}
                        fontFamily='sans-serif'
                        href='https://maps.app.goo.gl/wxxRXCiupnE47tdaA'
                    >
                        <Text as='u'>Avenida Pedro Parra Centeno 299, Tlajomulco de Zúñiga, Jalisco</Text>
                    </Link>
                </Flex>

            </Flex>

        </Flex>
    )
}

export default Cercanias