import React, { useState, useEffect } from 'react'
import {
    Flex,
    useMediaQuery,
    useTheme,
    Box,
    Text,
    Button,
    Link,
} from '@chakra-ui/react';
import ButtonActionModal from './ButtonActionModal';
import '../styles/parallax.css';
import '../styles/CTA.css';

const CTA = ({
    ctaNum,
    text1,
    text2,
    text3,
    font1,
    font2,
    font3,
    fontMb1,
    fontMb2,
    fontMb3,
    type1,
    type2,
    type3,
    ...props }) => {
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [screenSize, getDimension] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
    });

    const assets = [
        {
            imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/CTA.webp',
            imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/mobile/fondobg-Mb-1.png'
        },
        {
            imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/CTA2.webp',
            imgUrlMb: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/fondo/mobile/fondobg-Mb-2.png'
        },
    ];

    const setDimensions = () => {
        getDimension({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener("resize", setDimensions);

        return () => {
            window.removeEventListener("resize", setDimensions);
        };
    }, [screenSize]);

    return (
        <Box
            id='cta'
            {...props} w='full'
            paddingInline="0"
            position="relative"
        >
            <Flex id='inicio'
                h="70vh"
                className='content-cta'
                backgroundImage={{ base: assets[ctaNum].imgUrlMb, lg: assets[ctaNum].imgUrl }}
                backgroundSize={'cover'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
            >
                <div className='shadow-img'></div>
                <Flex
                    height="70vh"
                    w="100%"
                    position={"absolute"}
                    backgroundColor={"#0f312c71"}>
                </Flex>
                <Flex
                    zIndex={1}
                    flexDirection={"column"}
                    color={"#FFFFFF"}
                    textAlign={"center"}
                    alignItems={"center"} width={"100%"}
                >
                    <Text
                        lineHeight={1.5}
                        fontSize={isGreaterThanMd ? font1 : fontMb1}
                        color={'white'}
                        fontFamily="Shne-Breit-Bold"
                        letterSpacing='wider'
                        textTransform={'uppercase'}
                    >
                        {text1}
                    </Text>
                    <Text
                        mb={10}
                        lineHeight={1}
                        fontSize={isGreaterThanMd ? font2 : fontMb2}
                        color={'white'}
                        fontFamily="Shne-Breit-Bold"
                        letterSpacing='wider'
                        textTransform='uppercase'
                    >
                        {text2}
                    </Text>

                    <ButtonActionModal
                        title={'QUIERO SER CONTACTADO'}
                        idFormHs={"376c0c7a-7245-4082-9128-c630dbf59b07"}
                        thankYouPage={"thankyoupage-agenda-cita"}
                        bgColor={'rgba(172, 255, 197, 1)'}
                        hover={"#7CB48D"}
                        fontColor='#000000'
                        txtSize={500}
                        p={'11px'}
                        px={3}
                        size='30px'
                        fontSize={isGreaterThanMd ? 'lg' : 'sm'}
                        w={isGreaterThanMd ? 'auto' : 'full'}
                        alignSelf={'center'}
                    />
                </Flex>
            </Flex>
        </Box>
    )
}

export default CTA