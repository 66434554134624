import React, { useState } from 'react'
import {
  Flex,
  Text,
  useMediaQuery,
  useTheme,
  IconButton,
  Stack,
  Box,
  Container,
} from '@chakra-ui/react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { listImages } from '../resource/list';
import "../styles/styled.css";
import "../styles/descripcion.css";
import ArrowLeftIcon from "../assets/svgs/ArrowLeftSVG";
import ArrowRightIcon from "../assets/svgs/ArrowRightSVG";

const Desarrollador = () => {
  const { breakpoints } = useTheme();
  const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [isGreaterThanLg] = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => {
    activeIndex === 0 ? (
      setActiveIndex(listImages[1].images.length - 1)) :
      setActiveIndex(activeIndex - 1);
  };

  const slideNext = () => {
    activeIndex === listImages[1].images.length - 1 ? (
      setActiveIndex(0))
      : setActiveIndex(activeIndex + 1)
  };

  const assets = [
    // { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/logos/logo-sma.svg' },
    { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/desarrollador/icon-ibosa.png' }
  ];

  const TextSCC = () => (
    <Flex width={"100%"}>
      <Box mt={10}>
        <img
          src={assets[0].imgUrl}
          width='100'
          height='100'
          alt='descripcion'
        />
        <Text
          mt={5}
          fontFamily='Shne-Breit-Halbfett'
          fontSize={'20px'}
          color='rgba(84, 84, 84, 1)'
          lineHeight={'21.6px'}
        >
          Experiencia, diseño y urbanización
        </Text>
        <br />
        <Text
          fontSize={"18px"}
          fontWeight={400}
          fontFamily='Shne-Breit-Regular'
          textAlign={{ base: "justified", lg: "left" }}
          lineHeight={"1.2"}
        >
          Los fundadores de IBUSINESS OFFICE CAMPUS, también conocidos como IBOSA, 
          tienen gran experiencia en diseño, construcción y urbanización en 
          proyectos industriales, habitacionales y turísticos. Además, han 
          llevado a cabo instalaciones de servicios como electricidad, agua 
          potable y sistemas de drenaje en varias ciudades de México.
        </Text>
      </Box>
    </Flex>
  )

  return (
    <Container
      maxW='7xl'
      my={'10rem'}
      px={{ base: 4, md: 10, lg: 10 }}
      id="desarrollador"
    >
      <Flex
        width='100%'
        display={{ base: 'block', lg: 'flex' }}
      >
        <Box
          width={{ base: '100%', lg: '50%' }}
          mr={{ base: 0, lg: 10 }}
          mb={{ base: 10, lg: 0 }}
          display={{ base: 'none', lg: 'block' }}>
          <TextSCC />
        </Box>

        <Stack
          width={{ base: '90%', sm: '80%', md: '70%', lg: '55%' }}
          ml={{ base: -5, sm: 8, md: 16, lg: -5, xl: 0 }}
          align='center'
        >
          <Flex
            className='section-construvida-carousel'
            width={{ base: "100%", sm: '80%', md: '400px', lg: '450px' }}
            alignItems='center'
          >
            <IconButton
              icon={<ArrowLeftIcon color="black" />}
              rounded="full"
              border="0"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              //disabled={page <= 1}
              onClick={slidePrev}
              position="relative"
              right={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
            <AliceCarousel
              mouseTracking
              //disableDotsControls
              //animationType="fadeout" 
              animationDuration={150}
              disableButtonsControls
              activeIndex={activeIndex}
              items={listImages[1].images}
              responsive={true}
              controlsStrategy="responsive"
              animationType='fadeout'
              //autoPlay={true}
              autoPlayInterval={5000}
              infinite={true}
              keyboardNavigation={true}
              disableDotsControls
              //autoHeight={false}
              maxHeight="500px"
            />
            <IconButton
              icon={<ArrowRightIcon color="black" />}
              rounded="full"
              border="0"
              colorScheme="brand"
              shadow="md"
              transitionDuration=".3s"
              _hover={{ shadow: "lg" }}
              onClick={slideNext}
              position="relative"
              left={{ base: "-4", md: -5 }}
              bg="white"
              zIndex="2"
            />
          </Flex>
        </Stack>
        <Box
          width={{ base: '100%', lg: '50%' }}
          mr={{ base: 0, lg: 10 }}
          mb={{ base: 10, lg: 0 }}
          display={{ base: 'block', lg: 'none' }}>
          <TextSCC />
        </Box>
      </Flex>
    </Container >
  )
}

export default Desarrollador