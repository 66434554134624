import React, { useState, useEffect } from 'react';
import {
    Flex,
    Text,
    useMediaQuery,
    useTheme,
    useDisclosure,
    Stack,
    Box,
    Image,
    Show,
    Hide,
    Highlight
} from '@chakra-ui/react';

import "../styles/styled.css";
import "../styles/home.css";
import Nav from './Nav';
import ModalCard from './ModalCard';

const Presentacion = () => {
    //Elementos para responsive
    const { breakpoints } = useTheme();
    const [isGreaterThanMd] = useMediaQuery(`(min-width: ${breakpoints.md})`);
    const [id, setId] = useState(1);

    const assets = [
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/ambienta3070/icons/circle-white.png' },
        { imgUrl: 'https://boomerang.s3.us-east-2.amazonaws.com/landings/thedistrict/icons/icon-key.png' },
    ];

    const [img, setImg] = useState('');
    const [text1, setText1] = useState('Terrenos en venta desde $4.5 MDP');
    const [text2, setText2] = useState("");
    const [nums, setNums] = useState(0);
    const [change, setChange] = useState(true);
    const { isOpen, onClose } = useDisclosure();

    const changeImage = (num) => {
        switch (num) {
            case 1:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/slide1.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/bg-mobile-1.webp'); }
                setText1("Terrenos en venta desde $4.5 MDP");
                setText2("");
                setId(1);
                break;
            case 2:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/slide2.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/bg-mobile-2.webp'); }
                setText1("Terrenos desde 600 m²");
                setText2("Una inversión inteligente");
                setId(2);
                break;
            case 3:
                if (isGreaterThanMd) {
                    setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/slide3.webp');
                } else { setImg('https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/bg-mobile-3.webp'); }
                setText1("Conexión que fortalece tu inversión");
                setId(3);
                break;

            default:
                break;
        }
    }

    const changeImg = () => {
        setTimeout(() => {
            var sum = nums + 1;
            setNums(sum);
            setChange(!change);
            if (nums === 3) {
                setNums(1);
            }
            changeImage(nums);
        }, 8000);
    }

    useEffect(() => {
        // Reacciona dependiendo al cambio de pantalla y asigna la imagén ajustada
        if (isGreaterThanMd) {
            setImg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/slide${id}.webp`);
        } else {
            setImg(`https://boomerang.s3.us-east-2.amazonaws.com/landings/tecnosur/slides/bg-mobile-${id}.webp`);
        }
    }, [isGreaterThanMd]);

    useEffect(() => {
        changeImg();
    }, [change]);

    const showText = () => {
        switch (id) {
            case 1:
                return (
                    <>
                        {/* ========= DESKTOP ========== */}
                        <Hide below='md'>
                            <Stack
                                align='flex-end'
                                mt={{ md: '16vh', lg: '18vh' }}
                            >
                                <Box w={{ sm: '33%', lg: '38%' }}>
                                    <Text
                                        fontSize={{ sm: '3xl', lg: '4xl', xl: '5xl' }}
                                        fontWeight='500'
                                        align='center'
                                        fontFamily='Shne-Breit-Regular'
                                    >
                                        <Highlight
                                            query='$3.9 MDP'
                                            styles={{
                                                color: 'white',
                                                fontWeight: '900'
                                            }}
                                            fontFamily='Shne-Breit-Bold'
                                        >
                                            {text1}
                                        </Highlight>
                                    </Text>
                                    <Text
                                        fontSize={{ sm: '3xl', lg: '4xl', xl: '5xl' }}
                                        align='center'
                                        // textTransform='uppercase'
                                        fontWeight='700'
                                        color='rgba(172, 255, 197, 1)'
                                        fontFamily='Shne-Breit-Bold'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box position='absolute' top='96vh' right='18vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Hide>
                        {/* ========= MOBILE ========== */}
                        <Show below='md'>
                            <Stack mt={'65vh'} mx={4} align='center'>
                                <Box w='80%'>
                                    <Text
                                        fontSize='3xl'
                                        fontWeight='500'
                                        align='center'
                                        fontFamily='Shne-Breit-Regular'
                                    >
                                        <Highlight
                                            query='$3.9 MDP'
                                            styles={{
                                                color: 'white',
                                                fontWeight: '900',
                                                fontFamily: 'Shne-Breit-Bold'
                                            }}
                                        >
                                            {text1}
                                        </Highlight>
                                    </Text>
                                </Box>
                                <Box p={2} w='90%' mt={-4}>
                                    <Text
                                        fontSize='3xl'
                                        align='center'
                                        // textTransform='uppercase'
                                        fontFamily='Shne-Breit-Bold'
                                        color='rgba(172, 255, 197, 1)'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            case 2:
                return (
                    <>
                        {/* ========= DESKTOP ========= */}
                        <Hide below='md'>
                            <Stack mt={{ md: '18vh', lg: '25vh' }} align='flex-start' ml={4}>
                                <Box w='35%'>
                                    <Text
                                        fontSize={{ md: '3xl', lg: '4xl', xl: '5xl' }}
                                        fontWeight='500'
                                        align='center'
                                        fontFamily='Shne-Breit-Regular'
                                    >
                                        <Highlight
                                            query='612 m2'
                                            styles={{
                                                color: 'white',
                                                fontWeight: '900',
                                                fontFamily: 'Shne-Breit-Bold'
                                            }}
                                        >
                                            {text1}
                                        </Highlight>
                                    </Text>
                                    <Text
                                        fontSize={{ md: '3xl', lg: '4xl' }}
                                        align='center'
                                        fontFamily='Shne-Breit-Bold'
                                        color='rgba(172, 255, 197, 1)'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box position='absolute' top='95vh' left='18vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Hide>
                        {/* ========= MOBILE ========== */}
                        <Show below='md'>
                            <Stack mt={'66vh'} mx={4} align='center'>
                                <Text
                                    width='80%'
                                    fontSize='3xl'
                                    fontWeight='500'
                                    align='center'
                                >
                                    <Highlight
                                        query='612 m2'
                                        styles={{
                                            color: 'white',
                                            fontWeight: '900'
                                        }}
                                    >
                                        {text1}
                                    </Highlight>
                                </Text>
                                <Box p={2} w='100%' mt={-4}>
                                    <Text
                                        fontSize='3xl'
                                        align='center'
                                        // textTransform='uppercase'
                                        fontWeight='700'
                                        color='rgba(172, 255, 197, 1)'
                                    >
                                        {text2}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            case 3:
                return (
                    <>
                        <Hide below='md'>
                            <Stack
                                align='flex-end'
                                mt={{ md: '12vh', lg: '14vh' }}
                            >
                                <Box w={{ md: '36%', lg: '38%' }}>
                                    <Text
                                        fontSize={{ md: '3xl', lg: '4xl', xl: '5xl' }}
                                        fontWeight='500'
                                        align='center'
                                        fontFamily='Shne-Breit-Regular'
                                    >
                                        <strong>Conexión</strong> que fortalece tu <strong>inversión</strong>
                                    </Text>
                                    <Text
                                        fontSize={{ md: '3xl', lg: '4xl' }}
                                        align='center'
                                        fontFamily='Shne-Breit-Bold'
                                        color='rgba(172, 255, 197, 1)'
                                    >
                                        ·Ubicación <br />
                                        ·Conectividad <br />
                                        ·Plusvalía
                                    </Text>
                                </Box>
                                <Box position='absolute' top='96vh' right='18vh'>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Hide>
                        <Show below='md'>
                            <Stack mt={'65vh'} mx={4} align='center'>
                                <Box w='90%'>
                                    <Text
                                        fontSize='3xl'
                                        fontWeight='500'
                                        align='center'
                                    >
                                        <strong>Conexión</strong> que fortalece tu <strong>inversión</strong>
                                    </Text>
                                    <Text
                                        fontSize='2xl'
                                        align='center'
                                        // textTransform='uppercase'
                                        fontWeight='700'
                                        color='rgba(172, 255, 197, 1)'
                                    >
                                        ·Ubicación <br />
                                        ·Conectividad <br />
                                        ·Plusvalía
                                    </Text>
                                </Box>
                                <Box>
                                    <Text fontSize='11px'>
                                        *Precios sujetos a cambios sin previo aviso
                                    </Text>
                                </Box>
                            </Stack>
                        </Show>
                    </>
                )
            default:
                break;
        }
    }

    return (
        <>
            <Flex display={"block"}>
                <Nav />
            </Flex>
            <Box bg='#FFF' h={{ base: '40px', md: '85px' }} w='100%'></Box>
            <Flex id='presentacion' padding={{ base: '0rem', md: "1rem" }} height={"100vh"}>
                <Flex
                    mt={{ base: -10, xs: 0 }}
                    flexDirection={isGreaterThanMd ? "row" : "column"}
                    color={"#FFF"}
                    backgroundImage={img}
                    backgroundSize={{ base: 'fit', sm: 'cover' }}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'}
                    position={"relative"}
                    width={"100%"}
                    height={"100vh"}
                    borderRadius={"28px"}
                >
                    <Box width={"100%"}>
                        {showText()}
                    </Box>
                    <Flex
                        id='sc-points'
                        justifyContent="end"
                        display={isGreaterThanMd ? 'flex' : 'none'}
                        position={'relative'}
                        alignItems="center"
                        paddingLeft={"2rem"}
                        bottom="8"
                    >
                        <Box position={'absolute'} zIndex={1}>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(1)}>
                                <img src={id === 1 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(2)}>
                                <img src={id === 2 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            <Flex mb={2} justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(3)}>
                                <img src={id === 3 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex>
                            {/* <Flex justifyContent={"end"} marginRight={"1rem"} _hover={{ cursor: "pointer" }} className='points' onClick={() => changeImage(4)}>
                                <img src={id === 4 ? assets[0].imgUrl : assets[1].imgUrl} style={{ width: "14px" }} width='50' height='50' alt='icon' />
                            </Flex> */}
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            {isOpen ?
                <ModalCard
                    isOpen={isOpen}
                    onClose={onClose}
                    title={"Quiero ser contactado"}
                    idForm={"22b54777-673f-44b6-bfd8-043fee1a6931"}
                /> : null
            }
        </>
    );
}

export default Presentacion;