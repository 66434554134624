import axios from 'axios';
import fileDownload from 'js-file-download';

export const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
}

export const list = [
    {
        bold: true,
        num: "10%",
        pay: "2 PAGOS",
        tab: [
            { item1: "· Primer pago de enganche a la firma del contrato", item2: "90%"},
            { item1: "· Ultimo pago a la entrega", item2: "10%"},
        ]
    },
    {
        bold: false,
        num: "7%",
        pay: "4 PAGOS",
        tab: [
            { item1: "· Primer pago de enganche a la firma del contrato", item2: "50%"},
            { item1: "· Segundo pago a los 30 días", item2: "20%"},
            { item1: "· Tercer pago a los 60 días", item2: "20%"},
            { item1: "· Ultimo pago a la entrega", item2: "10%"},
        ]
    },
    {
        bold: false,
        num: "5%",
        pay: "8 PAGOS",
        tab: [
            { item1: "· Primer pago de enganche a la firma del contrato", item2: "30%"},
            { item1: "· Seis pagos en 6 meses", item2: "60%"},
            { item1: "· Último pago a la entrega", item2: "10%"}
        ]
    },
    {
        bold: false,
        num: "3%",
        pay: "13 PAGOS",
        tab: [
            { item1: "· Primer pago de enganche a la firma del contrato", item2: "25%"},
            { item1: "· Doce pagos en 12 meses", item2: "75%"},
        ]
    },
    {
        bold: false,
        num: "0%",
        pay: "21 PAGOS",
        tab: [
            { item1: "· Primer pago de enganche a la firma del contrato", item2: "5%"},
            { item1: "· Segundo pago a los 30 días", item2: "5%"},
            { item1: "· Dieciocho pagos en 18 meses", item2: "30%"},
            { item1: "· Último pago a la entrega", item2: "60%"}
        ]
    }
  ];
